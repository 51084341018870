<template>
  <div class="barge-device" id="bargeDevice">
    <el-row style="width:100%;" type="flex" align="center">
      <!-- 开关、转表 -->
      <el-col :span="7" class="charts-wp">
        <el-form class="form-wp">
          <el-row :gutter="15">
            <el-col :span="15">
              <el-form-item>
                <el-select v-model="bargeName" :placeholder="$t('pleaseSelectBarge')" size="small" @change="onSearch">
                  <el-option
                    v-for="item in bargeList"
                    :key="item"
                    :label="item"
                    :value="item">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <!-- <el-col :span="5">
              <el-form-item>
                <el-button type="primary" @click="onSearch" size="small">{{$t('query')}}</el-button>
              </el-form-item>
            </el-col> -->
            <el-col :span="9">
              <el-form-item>
                <el-select v-model="intervalTime" size="small" @change="intervalGetDatas">
                  <el-option :label="'2S ' + $t('update')" value="2"></el-option>
                  <el-option :label="'5S ' + $t('update')" value="5"></el-option>
                  <el-option :label="'10S ' + $t('update')" value="10"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
        <div class="float-left-top">
          <dv-border-box-12 :color="['#003b51', '#109eb5']" backgroundColor="#041d35" class="border-item">
            <el-row type="flex" align="center">
              <el-col :span="16">
                <ve-gauge
                  :data="speedChartDataP"
                  height="220px"
                  :settings="chartSettings">
                </ve-gauge>
              </el-col>
              <el-col :span="8" class="flex-state">
                <h3 class="state-name">C.O.P (P)</h3>
                <em v-if="pumpOn.P" class="lamp" style="background-color:#5dc12b; border-color:#d4ffdd">RUN</em>
                <em v-if="!pumpOn.P" class="lamp" style="background-color:#d05050; border-color:#f1d6d6">STOP</em>
                <!-- <el-button
                  type="danger"

                  :disabled="!pumpOn.P"
                  :loading="offPumpLoading.P"
                  @click="offPumpHandler('P')"
                >{{$t('stop')}}</el-button> -->
              </el-col>
            </el-row>
          </dv-border-box-12>
          <!-- <dv-border-box-12 :color="['#003b51', '#109eb5']" backgroundColor="#041d35" class="border-item">
            <el-row type="flex" align="center">
              <el-col :span="16" class="flex-column">
                <h3 class="state-name">CONTROL VALVE</h3>
                <div class="valve-chart">
                  <div :class="`middle-line-${valveOn}`"></div>
                  <em class="el-icon-caret-right" :class="`icon-${valveOn}`" style="margin-right:-12px;"></em>
                  <em class="el-icon-caret-left" :class="`icon-${valveOn}`" style="margin-left:-13px;"></em>
                </div>
              </el-col>
              <el-col :span="8" class="flex-state">
                <em v-if="valveOn" class="lamp" style="background-color:#5dc12b; border-color:#d4ffdd">OPEN</em>
                <em v-if="!valveOn" class="lamp" style="background-color:#d05050; border-color:#f1d6d6">STOP</em>
                <el-button
                  type="danger"

                  :disabled="!valveOn"
                  :loading="offValveLoading"
                  @click="offValveHandler"
                >{{$t('close')}}</el-button>
              </el-col>
            </el-row>
          </dv-border-box-12> -->
          <dv-border-box-12 :color="['#003b51', '#109eb5']" backgroundColor="#041d35" class="border-item">
            <el-row type="flex" align="center">
              <el-col :span="16">
                <ve-gauge
                  :data="speedChartDataS"
                  height="220px"
                  :settings="chartSettings">
                </ve-gauge>
              </el-col>
              <el-col :span="8" class="flex-state">
                <h3 class="state-name">C.O.P. (S)</h3>
                <em v-if="pumpOn.S" class="lamp" style="background-color:#5dc12b; border-color:#d4ffdd">RUN</em>
                <em v-if="!pumpOn.S" class="lamp" style="background-color:#d05050; border-color:#f1d6d6">STOP</em>
                <!-- <el-button
                  type="danger"

                  :disabled="!pumpOn.S"
                  :loading="offPumpLoading.S"
                  @click="offPumpHandler('S')"
                >{{$t('stop')}}</el-button> -->
              </el-col>
            </el-row>
          </dv-border-box-12>
        </div>
      </el-col>
      <!-- 48个sensor -->
      <el-col :span="17" class="sensor-wp">
        <!-- <h3 class="table-name">SENSORS</h3> -->
        <div class="sensor-header">
          <el-radio-group v-model="sensorRadio" fill="#3c76af" text-color="#ffffff" size="small">
            <el-radio-button label="1">Tanks Status</el-radio-button>
            <el-radio-button label="2">Pumps and Line Pressure</el-radio-button>
          </el-radio-group>
          <em
            class="el-icon-refresh"
            @click="reload"
            style="font-size:18px; padding:10px; cursor:pointer; color:#fff;">
          </em>
        </div>
        <div class="progress-group" v-show="sensorRadio==='1'">
          <dv-border-box-12
            class="progress-item"
            :color="['#24486f', '#1ec8da']"
            backgroundColor="#041d35"
            v-for="item in tanksP"
            :key="item.name">
            <h3>{{item.name}}</h3>
            <!-- <el-progress
              type="dashboard"
              :percentage="10"
              :width="progressSize"
              :stroke-width="10"
              :color="progressColors">
            </el-progress> -->
            <div class="percentage-text">{{item.percentage}}%</div>
            <div class="percentage-float" :style="{ height: `${item.percentage}%` }"></div>
            <!-- <dv-water-level-pond class="percentage-float" :config="{
              data: [16],
              waveNum: 2,
              waveHeight: 6,
              waveOpacity: 0.9,
              colors: ['#0e5584', '#0e5584']
            }"/> -->
            <el-row class="progress-row" type="flex">
              <!-- <el-col :span="12">
                <div class="flex-column">
                  <em class="dot-icon"></em>
                  <span>H.H</span>
                </div>
                <div class="flex-column">
                  <em class="dot-icon"></em>
                  <span>H.A</span>
                </div>
              </el-col> -->
              <el-col :span="24" class="progress-text">
                <p><span>{{$t('temp')}}</span> <span>{{item.temperature}}℃</span></p>
                <p><span>{{$t('level')}}</span> <span>{{item.level}}m</span></p>
              </el-col>
            </el-row>
          </dv-border-box-12>
        </div>
        <div class="progress-group" v-show="sensorRadio==='1'">
          <dv-border-box-12
            class="progress-item"
            :color="['#24486f', '#1ec8da']"
            backgroundColor="#041d35"
            v-for="item in tanksS"
            :key="item.name">
            <h3>{{item.name}}</h3>
            <div class="percentage-text">{{item.percentage}}%</div>
            <div class="percentage-float" :style="{ height: item.percentage + '%' }"></div>
            <!-- <dv-water-level-pond class="percentage-float" :config="{
              data: [50],
              waveNum: 2,
              waveHeight: 6,
              waveOpacity: 0.9,
              colors: ['#0e5584', '#0e5584']
            }"/> -->
            <el-row class="progress-row" type="flex">
              <!-- <el-col :span="12">
                <div class="flex-column">
                  <em class="dot-icon"></em>
                  <span>H.H</span>
                </div>
                <div class="flex-column">
                  <em class="dot-icon"></em>
                  <span>H.A</span>
                </div>
              </el-col> -->
              <el-col :span="24" class="progress-text">
                <p><span>{{$t('temp')}}</span> <span>{{item.temperature}}℃</span></p>
                <p><span>{{$t('level')}}</span> <span>{{item.level}}m</span></p>
              </el-col>
            </el-row>
          </dv-border-box-12>
        </div>
        <el-row>
          <el-col :span="18">
            <div class="watchs" v-if="sensorRadio==='2'">
              <dv-border-box-12 :color="['#24486f', '#1ec8da']" backgroundColor="#041d35" class="watchs-item">
                <h3>NO.1 H.F.O C.O.P</h3>
                <el-row type="flex">
                  <el-col :span="12">
                    <ve-gauge
                      :data="pressureData('NO.1 H.F.O C.O.P INLET')"
                      height="125px"
                      width="125px"
                      :settings="chartBar1">
                    </ve-gauge>
                    <h3 class="detail">INLET</h3>
                  </el-col>
                  <el-col :span="12">
                    <ve-gauge
                      :data="pressureData('NO.1 H.F.O C.O.P OUTLET')"
                      height="125px"
                      width="125px"
                      :settings="chartBar2">
                    </ve-gauge>
                    <h3 class="detail">OUTLET</h3>
                  </el-col>
                </el-row>
              </dv-border-box-12>
              <dv-border-box-12 :color="['#24486f', '#1ec8da']" backgroundColor="#041d35" class="watchs-item">
                <h3>NO.2 H.F.O C.O.P</h3>
                <el-row type="flex">
                  <el-col :span="12">
                    <ve-gauge
                      :data="pressureData('NO.2 H.F.O C.O.P INLET')"
                      height="125px"
                      width="125px"
                      :settings="chartBar1">
                    </ve-gauge>
                    <h3 class="detail">INLET</h3>
                  </el-col>
                  <el-col :span="12">
                    <ve-gauge
                      :data="pressureData('NO.2 F.F.O C.O.P OUTLET')"
                      height="125px"
                      width="125px"
                      :settings="chartBar2">
                    </ve-gauge>
                    <h3 class="detail">OUTLET</h3>
                  </el-col>
                </el-row>
              </dv-border-box-12>
            </div>
            <div class="watchs" v-if="sensorRadio==='2'">
              <dv-border-box-12 :color="['#24486f', '#1ec8da']" backgroundColor="#041d35" class="watchs-item">
                <h3>D.O.C.O.P</h3>
                <el-row type="flex">
                  <el-col :span="12">
                    <ve-gauge
                      :data="pressureData('D.O.C.O.P INLET')"
                      height="125px"
                      width="125px"
                      :settings="chartBar3">
                    </ve-gauge>
                    <h3 class="detail">INLET</h3>
                  </el-col>
                  <el-col :span="12">
                    <ve-gauge
                      :data="pressureData('D.O.C.O.P OUTLET')"
                      height="125px"
                      width="125px"
                      :settings="chartBar2">
                    </ve-gauge>
                    <h3 class="detail">OUTLET</h3>
                  </el-col>
                </el-row>
              </dv-border-box-12>
              <dv-border-box-12 :color="['#24486f', '#1ec8da']" backgroundColor="#041d35" class="watchs-item">
                <h3>STRIPPING PUMP</h3>
                <el-row type="flex">
                  <el-col :span="12">
                    <ve-gauge
                      :data="pressureData('STRIPPING PUMP INLET')"
                      height="125px"
                      width="125px"
                      :settings="chartBar3">
                    </ve-gauge>
                    <h3 class="detail">INLET</h3>
                  </el-col>
                  <el-col :span="12">
                    <ve-gauge
                      :data="pressureData('STRIPPING PUMP OUTLET')"
                      height="125px"
                      width="125px"
                      :settings="chartBar2">
                    </ve-gauge>
                    <h3 class="detail">OUTLET</h3>
                  </el-col>
                </el-row>
              </dv-border-box-12>
            </div>
          </el-col>
          <el-col :span="6">
            <dv-border-box-12
              class="watchs"
              :color="['#24486f', '#1ec8da']"
              backgroundColor="#041d35"
              style="margin-top:8px;"
              v-if="sensorRadio==='2'">
              <div class="watchs-item watchs-column">
                <ve-gauge
                  :data="pressureData('NO.1 CARGO PIPE')"
                  height="120px"
                  width="120px"
                  :settings="chartBar2">
                </ve-gauge>
                <h3>NO.1 CARGO PIPE</h3>
                <ve-gauge
                  :data="pressureData('NO.2 CARGO PIPE')"
                  height="120px"
                  width="120px"
                  :settings="chartBar2">
                </ve-gauge>
                <h3>NO.2 CARGO PIPE</h3>
                <ve-gauge
                  :data="pressureData('NO.3 CARGO PIPE')"
                  height="120px"
                  width="120px"
                  :settings="chartBar2">
                </ve-gauge>
                <h3>NO.3 CARGO PIPE</h3>
              </div>
            </dv-border-box-12>
          </el-col>
        </el-row>
      </el-col>
    </el-row>
    <div class="mass-flow">
      <dv-scroll-board class="iot-list" :config="flowmeterConfig" />
      <!-- <dv-border-box-12
        class="mass-flow-border"
        :color="['#24486f', '#1ec8da']">
      </dv-border-box-12> -->
    </div>
    <dv-border-box-12
      class="mass-flow-border"
      :color="['#24486f', '#1ec8da']">
    </dv-border-box-12>
  </div>
</template>

<script>
import VeGauge from 'v-charts/lib/gauge.common'
// import BorderBox from './borser-box'
// import sensorList from './sensor'
import { chartSettings, chartSmall, iotConfig } from './setting'

const DEVICE_SERVER = 'https://web.bunkerchain.io/device'

export default {
  components: { VeGauge },
  data () {
    this.chartSettings = chartSettings
    this.chartBar1 = chartSmall('Kpa', -100, 500, 6)
    this.chartBar2 = chartSmall('Kpa', 0, 1600, 4)
    this.chartBar3 = chartSmall('Kpa', -100, 600, 7)
    return {
      bargeName: '',
      bargeList: [],
      flowmeterData: [],
      offPumpLoading: {
        P: false,
        S: false
      },
      offValveLoading: false,
      pumpOn: {
        P: null,
        S: null
      },
      valveOn: false,
      speedValue: {
        P: 0,
        S: 0
      },
      tanksP: [6, 5, 4, 3, 2, 1],
      tanksS: [6, 5, 4, 3, 2, 1],
      pressure: [],
      flowmeterConfig: {},
      progressColors: [
        { color: '#3989d6', percentage: 50 },
        { color: '#da723a', percentage: 80 },
        { color: '#ce3c09', percentage: 100 }
      ],
      capsuleDatas: [],
      sensorRadio: '1',
      // windowWidth: 0,
      // windowWidthTimer: 0,
      allTimer: 0,
      intervalTime: '5',
      // showCharts: true,
      progressSize: 92,
      percentage: 50
    }
  },
  computed: {
    speedChartDataP () {
      return {
        columns: ['type', 'value'],
        rows: [
          { type: 'speed', value: this.speedValue.P }
        ]
      }
    },
    speedChartDataS () {
      return {
        columns: ['type', 'value'],
        rows: [
          { type: 'speed', value: this.speedValue.S }
        ]
      }
    }
  },
  watch: {
    // windowWidth (v, o) {
    //   this.showCharts = false
    //   setTimeout(() => {
    //     this.showCharts = true
    //   }, 0)
    // },
    bargeList (v, o) {
      if (v.length > 0) {
        this.bargeName = localStorage.getItem('bargeName') || v[0]
        this.intervalGetDatas()
      }
    }
  },
  methods: {
    // 获取驳船数据
    getBargeList () {
      return this.$request.get({
        url: DEVICE_SERVER + '/getBoatList'
      }).then(res => {
        // 返回的数据格式和后端约定
        if (res.code === 0) {
          this.bargeList = res.data
        }
      })
    },
    // 获取sensor数据
    getSensorList () {
      this.$request.get({
        url: DEVICE_SERVER + '/values',
        params: { identity: this.bargeName }
      }).then(res => {
        let { code, data } = res
        if (code === 0) {
          data = data || []
          // this.capsuleDatas = data.filter(item => item.unit === 'MPa').map(item => Object.assign({}, item, {
          //   name: item.name.replace(/\s+/g, '&nbsp;&nbsp;')
          // }))
          this.pressure = data.filter(item => item.type === 'pressure')
          const levels = data.filter(item => item.type === 'level')
          const percentages = data.filter(item => item.type === 'percentage')
          const temperatures = data.filter(item => item.type === 'temperature')
          if (levels.filter(item => item.value && item.value >= 1).length === 0 || percentages.filter(item => item.value).length === 0 || temperatures.filter(item => item.value).length === 0) {
            return false
          }
          this.tanksP = [6, 5, 4, 3, 2, 1].map(num => {
            const name = levels.find(item => item.name.includes(num) && item.name.includes('P')) ? levels.find(item => item.name.includes(num) && item.name.includes('P')).name : num
            const level = levels.find(item => item.name.includes(num) && item.name.includes('P')) ? levels.find(item => item.name.includes(num) && item.name.includes('P')).value.toFixed(2) : 0
            const percentage = percentages.find(item => item.name.includes(num) && item.name.includes('P')) ? percentages.find(item => item.name.includes(num) && item.name.includes('P')).value : 0
            // const percentage = this.randomNum()
            const temperature = temperatures.find(item => item.name.includes(num) && item.name.includes('P')) ? temperatures.find(item => item.name.includes(num) && item.name.includes('P')).value : 0
            return { name, level, percentage, temperature }
          })
          this.tanksS = [6, 5, 4, 3, 2, 1].map(num => {
            const name = levels.find(item => item.name.includes(num) && item.name.includes('S')) ? levels.find(item => item.name.includes(num) && item.name.includes('S')).name : num
            const level = levels.find(item => item.name.includes(num) && item.name.includes('S')) ? levels.find(item => item.name.includes(num) && item.name.includes('S')).value.toFixed(2) : 0
            const percentage = percentages.find(item => item.name.includes(num) && item.name.includes('S')) ? percentages.find(item => item.name.includes(num) && item.name.includes('S')).value : 0
            // const percentage = this.randomNum()
            const temperature = temperatures.find(item => item.name.includes(num) && item.name.includes('S')) ? temperatures.find(item => item.name.includes(num) && item.name.includes('S')).value : 0
            return { name, level, percentage, temperature }
          })
          this.$nextTick(() => {
            this.setPercentColors()
          })
        }
      })
    },
    // 获取质量流量计数据
    getFlowmeterData () {
      const params = {
        limit: 30,
        page: 1
      }
      this.$request.get({
        url: '/app/flowmeterData/query',
        params
      }).then(res => {
        // 返回的数据格式和后端约定
        if (res.code === 0) {
          let list = res.data.map(item => JSON.parse(item.data))
          list = list.map(item => Object.assign({}, item, {
            time: item.Time ? getTime(item.Time.split('.')[0].replace('T', ' ')) : '',
            statusReports: '73'
          }))
          this.flowmeterConfig = iotConfig(this, list)
        }
      })
      const getTime = (t) => {
        const tp = this.$moment(t).valueOf()
        const reTime = this.$moment(tp + 8 * 60 * 60 * 1000).format('YYYY.MM.DD HH:mm')
        return reTime
      }
    },
    // 获取转速表
    getRpmStatus (type) {
      this.$request.get({
        url: DEVICE_SERVER + '/rpmStatus',
        params: { type, identity: this.bargeName }
      }).then(res => {
        const { code, data } = res
        if (code === 0) {
          this.speedValue[type] = data || 0
        }
      })
    },
    // 获取泵状态
    getPumpStatus (type) {
      this.$request.get({
        url: DEVICE_SERVER + '/pumpStatus',
        params: { type, identity: this.bargeName }
      }).then(res => {
        const { code, data } = res
        if (code === 0) {
          this.pumpOn[type] = data
        }
      })
    },
    // 获取阀状态
    getValveStatus () {
      this.$request.get({
        url: DEVICE_SERVER + '/valveStatus',
        params: { identity: this.bargeName }
      }).then(res => {
        const { code, data } = res
        if (code === 0) {
          this.valveOn = data
        }
      })
    },
    // 关泵
    offPumpHandler (type) {
      this.offPumpLoading[type] = true
      this.$request.get({
        url: DEVICE_SERVER + '/offPump',
        params: { type, identity: this.bargeName }
      }).then(res => {
        const { code } = res
        if (code === 0) {
          this.getPumpStatus(type)
          this.$message.success(this.$t('operationSuccess'))
        }
      }).finally(() => {
        window.setTimeout(() => {
          this.offPumpLoading[type] = false
        }, 30000)
      })
    },
    // 关阀
    offValveHandler () {
      this.offValveLoading = true
      this.$request.get({
        url: DEVICE_SERVER + '/offValve',
        params: { identity: this.bargeName }
      }).then(res => {
        const { code } = res
        if (code === 0) {
          this.getValveStatus()
          this.$message.success(this.$t('operationSuccess'))
        }
      }).finally(() => {
        window.setTimeout(() => {
          this.offValveLoading = false
        }, 30000)
      })
    },
    onSearch () {
      localStorage.setItem('bargeName', this.bargeName)
      this.intervalGetDatas()
    },
    intervalGetDatas () {
      if (!this.bargeName) {
        this.$message.error(this.$t('pleaseSelectBarge'))
        return false
      }
      const tim = parseInt(this.intervalTime) * 1000
      this.clearDeviceInterval()
      // 轮询设备数据和状态
      this.getSensorList()
      this.getPumpStatus('P')
      this.getPumpStatus('S')
      this.getValveStatus()
      this.getRpmStatus('P')
      this.getRpmStatus('S')
      this.allTimer = window.setInterval(() => {
        this.getSensorList()
        this.getPumpStatus('P')
        this.getPumpStatus('S')
        this.getValveStatus()
        this.getRpmStatus('P')
        this.getRpmStatus('S')
      }, tim)
    },
    clearDeviceInterval () {
      window.clearInterval(this.allTimer)
    },
    // 设置进度条渐变色
    setPercentColors () {
      document.querySelectorAll('.el-progress-bar__inner').forEach(item => {
        if (item.style.width > '79%' || item.style.width === '100%') {
          item.style.backgroundImage = 'linear-gradient(to right, #3989d6, #e83c28)'
        } else if (item.style.width > '49%') {
          item.style.backgroundImage = 'linear-gradient(to right, #3989d6, #de7932)'
        } else {
          item.style.backgroundImage = 'linear-gradient(to right, #4ea8ff, #4ea8ff)'
        }
      })
    },
    // 30 - 60 随机数
    randomNum () {
      return Math.floor(Math.random() * (50 - 10 + 1)) + 10
    },
    pressureData (name) {
      // 大气压表
      return {
        columns: ['type', 'value'],
        rows: [{
          type: 'speed',
          value: this.pressure.length > 0 ? this.pressure.find(item => item.name === name).value : 0
        }]
      }
    },
    reload () {
      this.$router.go(0)
    }
  },
  mounted () {
    this.getBargeList()
    this.getFlowmeterData()
    // 持续获取窗口宽度
    // this.windowWidth = document.getElementById('bargeDevice').clientWidth
    // this.windowWidthTimer = window.setInterval(() => {
    //   this.windowWidth = document.getElementById('bargeDevice').clientWidth
    // }, 3000)
  },
  beforeDestroy () {
    // window.clearInterval(this.windowWidthTimer)
    this.clearDeviceInterval()
  }
}
</script>

<style lang='scss' scoped>
.barge-device /deep/ {
  background-color: #060e1b;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  border-radius: 6px;
  box-shadow: 0 0 5px #060e1b;
  // min-height: 900px;
  min-width: 1400px;
  position: relative;
  padding: 10px;
  padding-bottom: 20px;
  .form-wp {
    padding: 5px 0 9px 0;
    margin: 0 auto;
    width: 90%;
    box-sizing: border-box;
    .el-form-item {
      margin-bottom: 0;
      .el-form-item__label {
        color: #DCDFE6;
      }
      .el-input__inner {
        background-color: #2d3b4a;
        border-color: #2d3b4a;
        color: #DCDFE6;
      }
      .el-button--primary {
        background-color: #327cc6;
        border-color: #327cc6;
      }
      .el-select {
        width: 100%;
      }
    }
  }
  .table-name {
    font-size: 23px;
    font-weight: 500;
    color: #DCDFE6;
    margin: 0;
    padding: 10px 0 0 0;
  }
  .float-left-top {
    // width: 80%;
    // position: absolute;
    // left: 0;
    // top: 80px;
    // z-index: 1;
    // padding: 0 60px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    .border-item {
      margin: 3px 0;
      padding: 4px;
      width: 90%;
      // background-color: #172740b5;
      // background-color: #041735;
    }
    .state-name {
      font-size: 16px;
      font-weight: 500;
      white-space: nowrap;
      color: #DCDFE6;
      text-align: center;
      margin: 0;
      padding: 5px;
    }
    .valve-chart {
      width: 150px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex: 1;
      padding-bottom: 15px;
      position: relative;
      .middle-line-true {
        position: absolute;
        top: calc(50% - 9px);
        height: 1px;
        background-color: #70ea33;
        width: 100%;
        box-shadow: 0 0 8px #fff;
      }
      .middle-line-false {
        position: absolute;
        top: calc(50% - 9px);
        height: 1px;
        background-color: #d05050;
        width: 100%;
      }
      .icon-true {
        color: #70ea33;
        font-size: 46px;
        text-shadow: 0 0 6px #fff;
      }
      .icon-false, .icon-null {
        color: #d05050;
        font-size: 46px;
      }
    }
    .flex-state {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 8px;
      // margin-bottom: 20px;
      .name {
        font-size: 30px;
        font-weight: 500;
        color: #DCDFE6;
        // padding-bottom: 10px;
      }
      .lamp {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 36px;
        height: 36px;
        font-size: 15px;
        color: #060e1b;
        font-weight: 900;
        font-style: normal;
        border-radius: 50%;
        border: 4px solid #DCDFE6;
        padding: 5px;
        margin: 5px 0 10px 0;
      }
      .speed-chart {
        width: 100px;
        height: 100px;
      }
    }
  }
  .charts-wp {
    .ve-gauge {
      padding-top: 12px;
    }
    .meter-name {
      width: 100%;
      font-size: 1.3vw;
      font-weight: 500;
      color: #DCDFE6;
      text-align: center;
      margin: 0;
      padding: 0;
      // padding-right: 60px;
      position: absolute;
      bottom: 40px;
      left: -20px;
    }
  }
  .el-button--danger {
    background-color: #d05050;
    border-color: #d05050;
  }
  .el-button--danger.is-disabled, .el-button--danger.is-disabled:active, .el-button--danger.is-disabled:focus, .el-button--danger.is-disabled:hover {
    background-color: #606266;
    border-color: #606266;
  }
  .flex-box {
    display: flex;
    align-items: center;
    p {
      padding-right: 20px;
    }
  }
  /** sensor */
  .sensor-wp {
    padding: 6px 8px;
    // padding-top: 20px;
    // height: 650px;
    .sensor-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 5px;
    }
    .sensor-list {
      width: 99%;
      height: calc(100% - 5vh);
    }
    .el-radio-button__inner {
      background: #2d3b4a;
      border-color: #2d3b4a;
      color: #c0c4cc;
    }
    .progress-group {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 10px;
      .progress-item {
        display: flex;
        flex-direction: column;
        align-items: center;
        // background-color: #4695d43d;
        // border-radius: 4px;
        // background-color: #132135;
        border-radius: 16px;
        padding: 7px;
        width: 16.2%;
        box-sizing: border-box;
        overflow: hidden;
        height: 240px;
        /** 10-29改 start */
        position: relative;
        .percentage-float {
          position: absolute;
          width: 100%;
          bottom: 0;
          border-radius: 0 0 10px 10px;
          // background-color: #155b8c;
          background-image: linear-gradient(#20789a, #1f519f);
          transition: height 1s ease;
          // position: absolute;
          // width: calc(100% + 16px);
          // height: calc(100% + 16px);
          // bottom: -8px;
          // left: -8px;
          // z-index: 0;
        }
        .dv-water-pond-level ellipse, .dv-water-pond-level rect {
          stroke-width: 0;
        }
        .dv-water-pond-level text {
          display: none;
        }
        .percentage-text {
          height: 92px;
          width: 100%;
          box-sizing: border-box;
          text-align: center;
          padding-top: 48px;
          font-size: 20px;
          font-weight: 800;
          color: #ccc;
          position: relative;
          z-index: 1;
          text-shadow: 0 0 3px #0b3b5d;
        }
        /** 10-29改 end */
        h3 {
          font-size: 14px;
          color: #DCDFE6;
          margin: 10px auto 8px auto;
          word-spacing: 5px;
          position: relative;
          text-align: center;
          z-index: 1;
        }
        .dot-icon {
          display: block;
          width: 16px;
          height: 16px;
          border-radius: 50%;
          background-color: #5dc12b;
          box-shadow: -3px 1px 3px #3a3a3a;
          margin-top: 10px;
          border: 4px solid #39542b;
        }
        .progress-row {
          width:100%;
          height:100%;
          position: absolute;
          top: 0;
          left: 0;
          .progress-text {
            display: flex;
            // flex-direction: column;
            justify-content: space-between;
            align-items: center;
            position: absolute;
            bottom: 0;
            padding: 5px 10px;
            p {
              margin: 5px 0;
              padding: 0;
              font-size: 14px;
              color: #E4E7ED;
              display: flex;
              flex-direction: column;
            }
          }
        }
        .el-progress__text {
          color: #C0C4CC;
        }
        .flex-column {
          align-items: flex-end;
          padding-right: 15px;
        }
        .gauge-cols {
          // height: 300px;
          width: 100%;
          .gauge-item {
            // height: 150px;
            display: flex;
            justify-content: center;
          }
        }
        .bar-name {
          position: absolute;
          width: 100%;
          left: 50%;
          bottom: 0;
          white-space: nowrap;
          transform:translate(-50%, 0);
          text-align: center;
          font-size: 12px;
        }
        .max-value {
          position: absolute;
          width: 100%;
          left: 50%;
          top: -10px;
          white-space: nowrap;
          transform:translate(-50%, 0);
          text-align: center;
          font-size: 13px;
          color: #909399;
        }
      }
    }
    .capsule-data {
      padding: 20px 50px 15px 0;
      .cap-item {
        display: flex;
        align-items: center;
        margin-top: 19px;
        .cap-name {
          width: 200px;
          text-align: right;
          padding: 0 15px;
          color: #DCDFE6;
        }
        .cap-progress {
          flex: 1;
        }
        .el-progress-bar__outer {
          background-color: #1b3756;
        }
        .cap-max {
          color: #909399;
        }
      }
    }
    .watchs {
      display: flex;
      flex-wrap: wrap;
      margin-top: 10px;
      &-item {
        padding: 17px;
        // background-color: #468ed430;
        // border-radius: 4px;
        // background-color: #132135;
        border-radius: 16px;
        box-sizing: border-box;
        margin: 0 11px 0px 0;
        flex: 1;
        .el-row {
          width: 100%;
          .el-col {
            display: flex;
            align-items: center;
            flex-direction: column;
          }
        }
        h3 {
          font-size: 14px;
          color: #DCDFE6;
          margin: 5px auto 8px auto;
          word-spacing: 5px;
          text-align: center;
          &.detail {
            margin-top: -10px;
            // margin-bottom: 3px;
          }
        }
        .ve-gauge {
          padding: 15px 10px;
        }
        &.watchs-column {
          display: flex;
          flex-direction: column;
          align-items: center;
          padding: 10px;
          h3 {
            margin-top: -10px;
            margin-bottom: 17px;
          }
          .ve-gauge {
            padding: 5px;
          }
        }
      }
    }
  }
  .flex-column {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #C0C4CC;
  }
  .mass-flow {
    width: 97%;
    margin-left: 0.6vw;
    overflow: auto;
    flex: 1;
    // padding: 15px;
    box-sizing: border-box;
    // border: 2px solid #24486f;
    border-radius: 15px;
    position: relative;
    margin-top: 8px;
    z-index: 1;
    .iot-list {
      min-width: 4300px;
      margin: 0 auto;
      height: 97%;
    }
  }

  .mass-flow-border {
    position: absolute;
    width: 97.2%;
    height: calc(100vh - 568px);
    bottom: 8px;
    left: 50%;
    transform: translateX(-49.6%);
    z-index: 0;
  }

  .bar-wp {
    position: relative;
    padding-top: calc(40px - 3%);
    padding-right: 5px;
    .bars {
      transform: rotate(-90deg);
      height: 200px;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      // margin-top: 50%;
      position: relative;
      z-index: 2;
      .progress-bar {
        width: 170px;
        margin: 15px 0;
        .el-progress-bar {
          padding-right: 35px;
        }
        .el-progress-bar__outer {
          overflow: unset;
          background-color: #060e1b;
        }
        .el-progress-bar__innerText {
          transform: rotate(90deg);
          font-size: 14px!important;
          font-weight: bold;
        }
      }
    }
  }

  .line-group {
    height: 19px;
    width: 134px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    position: absolute;
    left: -1px;
    top: calc(50% - 9px);
    z-index: 0;
    // border: 1px solid #f5f5f5;
    // border-width: 0px 1px 0px 1px;
    .line {
      width: 1px;
      height: 100%;
      background-color: #ebeef5;
      &.mini {
        width: 1px;
        height: 100%;
      }
    }
  }
  .dv-percent-pond text{
    font-size: 15px;
    font-weight: normal;
  }
}
</style>
